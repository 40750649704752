<template>
  <div>
    <div class="history">
      <div class="top">
        <h4>历史记录</h4>
        <van-icon name="delete-o" />
      </div>
      <div class="down">
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
      </div>
    </div>
    <div class="hot">
      <div class="top">
        <h4>热门搜索</h4>
      </div>
      <div class="down">
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
        <van-tag plain type="default">标签</van-tag>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.history,
.hot {
  text-align: left;
  padding: 0 10px;
  background-color: #fff;
  margin-bottom: 20px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-size: 18px;
    h4 {
      font-weight: normal;
    }
  }
  .down {
    .van-tag {
      font-size: 16px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 4px;
    }
  }
}
</style>

<template>
  <van-list :finished="finished" finished-text="没有更多了">
    <van-cell v-for="item in 6" :key="item" :title="item" />
  </van-list>
</template>

<script>
export default {
  data() {
    return {
      finished: true
    }
  },
  methods: {
    add() {}
  }
}
</script>

<style>
</style>

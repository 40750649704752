<template>
  <div>
    <van-dropdown-menu>
      <van-dropdown-item title="综合" disabled />
      <van-dropdown-item v-model="value1" title="价格" :options="option1" />
      <van-dropdown-item v-model="value2" title="分类" :options="option2" />
    </van-dropdown-menu>
    <van-empty v-if="isEmpty" image="search" description="没有符合条件的数据" />
    <product-list v-else />
  </div>
</template>

<script>
import ProductList from './ProductList'

export default {
  components: {
    ProductList
  },
  data() {
    return {
      isEmpty: false,
      value1: 0,
      value2: 'a',
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' }
      ]
    }
  }
}
</script>

<style>
</style>

<template>
  <div class="popup">
    <van-search v-model="iptVal" show-action shape="round" :placeholder="optPlaceHolder" @search="handleSearch" @cancel="handleCancel" />
    <history-hot v-if="blockShow === 1" />
    <my-list v-if="blockShow === 2" />
    <my-product v-if="blockShow === 3" />
  </div>
</template>

<script>
import HistoryHot from './components/HistoryHot'
import MyProduct from './components/MyProduct'
import MyList from './components/MyList'
export default {
  components: {
    HistoryHot,
    MyProduct,
    MyList
  },
  data() {
    return {
      iptVal: '',
      optPlaceHolder: 'optPlaceHolder',
      // 1.历史记录
      // 2.查询列表
      // 3. 产品列表
      blockShow: 1
    }
  },
  methods: {
    handleSearch() {
      console.log('do search')
      // 查询后显示产品列表组件
      this.blockShow = 3
    },
    handleCancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #efefef;
  z-index: 99;
}
</style>

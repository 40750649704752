<template>
  <div>
    <ul>
      <li v-for="item in 6" :key="item" @click="goDetail(item)">
        <img :src="imgSrc" width="100%" style="display:block" />
        <div class="van-ellipsis title">a鞢枯 在城地夺夺在栽地阿斯蒂芬茜苛栽栽栽栽栽植</div>
        <div class="price">{{ 89|formatMoney }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgSrc: require('@/assets/logo.png')
    }
  },
  methods: {
    goDetail(item) {
      this.$router.push('/productdetail/1')
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    width: 49%;
    background-color: #fff;
    text-align: center;
    line-height: 24px;
    margin-bottom: 10px;
    // .title {
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    .price {
      color: darkred;
    }
  }
}
</style>
